import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loadinganimation',
  templateUrl: './loadinganimation.component.html',
  styleUrls: ['./loadinganimation.component.scss']
})
export class LoadinganimationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
